<template>
  <div class="main-wrap">
    <a-spin :spinning="spinning">
      <div class="input-box">
        <h4 class="title-h4">发票抬头</h4>
        <p class="all-btn-small" @click="showModal" v-if="listdata.length">
          新建抬头
        </p>
      </div>
      <div class="card" v-for="(item, index) in listdata" :key="index">
        <div class="cardimg">
          <img src="@/assets/image/personalCenter/20220728-105939.png" alt="" />
        </div>
        <div class="cardcenter">
          <div class="top">
            <img src="@/assets/image/personalCenter/Frame.png" alt="" />
            <span class="span1">{{ item.invoiceHeader }}</span>
            <div class="default" v-if="item.headerType == 1">企业</div>
            <div class="default" v-if="item.headerType == 2">机关执业单位</div>
            <div class="default" v-if="item.headerType == 3">个人</div>
          </div>
          <div class="center">
            <span v-if="item.email" class="center_item">
              <img src="@/assets/image/personalCenter/Frame2.png" alt="" />{{
                item.email
              }}
            </span>
            <span v-if="item.dutyParagraph" class="center_item">
              <img src="@/assets/image/personalCenter/Frame3.png" alt="" />{{
                item.dutyParagraph
              }}
            </span>
            <!-- <span class="center_item">
            <img
              src="@/assets/image/personalCenter/Frame4.png"
              alt=""
            />{{item.companyPhone}}
          </span> -->
            <span v-if="item.bank" class="center_item">
              <img src="@/assets/image/personalCenter/Frame5.png" alt="" />{{
                item.bank
              }}
            </span>
            <span v-if="item.bankAccount" class="center_item">
              <img src="@/assets/image/personalCenter/Frame6.png" alt="" />{{
                item.bankAccount
              }}
            </span>
            <span v-if="item.companyAddress" class="center_item">
              <img src="@/assets/image/personalCenter/Frame7.png" alt="" />{{
                item.companyAddress
              }}
            </span>
            <span v-if="item.companyPhone" class="center_item">
              <img src="@/assets/image/personalCenter/Frame8.png" alt="" />{{
                item.companyPhone
              }}
            </span>
          </div>
          <div class="bottom">
            <span @click="showPop(2, item)">
              <img
                src="@/assets/image/personalCenter/20220728-105954.png"
                alt=""
              />编辑
            </span>
            <span @click="showPop(1, item.userInvoiceId)">
              <img
                src="@/assets/image/personalCenter/20220728-110000.png"
                alt=""
              />删除
            </span>
          </div>
        </div>
        <div class="cardimg">
          <img src="@/assets/image/personalCenter/20220728-105950.png" alt="" />
        </div>
      </div>

      <!-- <div class="btn-foot">
      <p class="cancel">取消</p>
    </div> -->
      <div class="none" v-if="!listdata.length && !spinning">
        <img src="@/assets/image/personalCenter/20220729-152835.png" alt="" />
        <span>暂无发票抬头，<a @click="showModal">新建发票抬头</a></span>
      </div>
    </a-spin>
    <TipsModal
      :isPublicTipsShow="isPublicTipsShow"
      tipsText="确认删除该抬头信息？"
      @setEvent="getEvent"
    />
    <!-- 添加发票 -->
    <invoiceModal
      :invoiceType="invoiceType"
      :isAddInvoiceModal="isAddInvoiceModal"
      :formemit="formemit"
      @onCancelAdd="onCancelAdd"
      @onConfirmAdd="onConfirmAdd"
    ></invoiceModal>
  </div>
</template>

<script>
import invoiceModal from "@/components/model/invoiceModal.vue"; // 添加发票抬头

export default {
  // 可用组件的哈希表
  components: { invoiceModal },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      // 地址参数
      formemit: {
        headerType: -1, //抬头类型 1.企业 2.机关执业单位 3.个人/非企业单位 4.其他
        invoiceHeader: "", //抬头
        dutyParagraph: "", //税号
        email: "", //电子邮箱
        bank: "", //开户银行
        bankAccount: "", //银行账号
        companyAddress: "", //企业地址
        companyPhone: "", //企业电话
      },
      invoiceType: 1, //1添加发票 3换开发票
      isPublicTipsShow: false, //删除发票抬头
      isAddInvoiceModal: false, //发票抬头弹框

      isHavePSW: false,
      tipsIndex: null,
      confirm: "", //确认密码
      // 弹窗内容
      visible: false,
      spinning: true,
      listdata: [],
      changeItem: null,
    };
  },
  // 事件处理器
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.$ajax({
        url: "/hxclass-pc/user/info",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          res.data.isLogin = true;
          this.$store.commit("updateUserInfo", res.data);
        }
      });
    },
    // 获取列表
    async getList() {
      this.spinning = true;
      let res = await this.$ajax({
        url: "/hxclass-pc/invoice/header/list",
        method: "get",
        params: {},
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // console.log(res);
          this.listdata = res.data;
        } else {
          this.message.error(res.message);
        }
      });
      this.spinning = false;
      return res;
    },
    // 添加发票弹框 - 取消
    onCancelAdd() {
      this.isAddInvoiceModal = false;
    },
    // 添加发票弹框 - 确定
    onConfirmAdd() {
      this.getList();
      this.isAddInvoiceModal = false;
    },
    inputChange() {
      this.tipsIndex = null;
    },
    // 打开提示框
    showPop(type, index) {
      if (type == 1) {
        // 删除地址
        this.popupTxt = "确认删除该地址吗？";
        // console.log('删除');
        this.changeItem = index;
        this.isPublicTipsShow = true;
      } else if (type == 2) {
        // 编辑抬头
        this.formemit = JSON.stringify(index);
        this.formemit = JSON.parse(this.formemit);
        this.invoiceType = 3;
        this.isAddInvoiceModal = true;
      } else if (type == 3) {
        // 取消默认
        this.popupTxt = "确认是否取消该默认地址？";
      }
    },
    // 弹窗回调事件
    getEvent() {
      /** 需要执行的任务 **/
      this.$ajax({
        url: "/hxclass-pc/invoice/header/" + this.changeItem,
        method: "DELETE",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.listdata.map((item, index) => {
            if (item.userInvoiceId == this.changeItem) {
              this.listdata.splice(index, 1);
              if (
                this.listdata.length &&
                this.pageSize == this.listdata.length
              ) {
                this.pageNum--;
              }
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
      this.isPublicTipsShow = false;
    },
    // 弹窗操作
    showModal() {
      this.formemit = {
        headerType: -1, //抬头类型 1.企业 2.机关执业单位 3.个人/非企业单位 4.其他
        invoiceHeader: "", //抬头
        dutyParagraph: "", //税号
        email: "", //电子邮箱
        bank: "", //开户银行
        bankAccount: "", //银行账号
        companyAddress: "", //企业地址
        companyPhone: "", //企业电话
      };
      this.isAddInvoiceModal = true;
      this.invoiceType = 1;
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 检查是否有密码
    this.isHavePSW = this.$store.state.userInfo.password ? true : false;
    this.getList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.input-box {
  display: flex;
  justify-content: space-between;
}
.btn-foot {
  margin-top: 40px;
  display: flex;
  .cancel {
    text-align: center;
    line-height: 40px;
    width: 138px;
    border-radius: 40px;
    height: 40px;
    color: @theme;
    margin-right: 54px;
    border: 1px solid @theme;
    cursor: pointer;
  }
}
.card {
  background-color: #fff;
  margin-bottom: 32px;
  .cardimg {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .cardcenter {
    width: 100%;
    padding: 0 24px;
    .top {
      display: flex;
      align-items: flex-end;
      margin-bottom: 15px;
      img {
        width: 17px;
        height: 19px;
      }
      .span1 {
        font-size: 18px;
        font-family: PingFangMedium;
        font-weight: 500;
        margin-left: 11px;
        color: #333333;
      }
      .span2 {
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 400;
        margin-left: 16px;
        color: #333333;
      }
    }
    .center {
      width: 100%;
      display: flex;
      word-break: normal;
      word-wrap: break-word;
      flex-wrap: wrap;
      border-bottom: 1px solid #ebebeb;
      .center_item {
        margin-bottom: 20px;
        width: 33%;
        font-size: 14px;
        display: inline-block;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        img {
          width: 18px;
          margin-right: 8px;
        }
      }
    }

    .bottom {
      width: 100%;
      margin-top: 24px;
      span {
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        margin-right: 24px;
        cursor: pointer;
      }
      img {
        width: 14px;
        margin-right: 4px;
      }
    }
    .default {
      width: 32px;
      height: 17px;
      background: #15b7dd;
      box-shadow: inset 0px 0px 4px 0px #c9f4ff;
      border-radius: 2px 2px 2px 2px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: 17px;
      margin-left: 16px;
    }
  }
}
.bottombtn {
  text-align: center;
  .ant-btn {
    width: 113px;
    height: 36px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    border-radius: 18px;
  }
}
.forminput {
  width: 300px;
  height: 41px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}
.modalcenter {
  width: 100%;
  .selects {
    width: 138px;
    height: 41px;
    margin-right: 10px;
    border-radius: 4px 4px 4px 4px;
  }
  .radio {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    margin-left: 100px;
  }
  p {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    display: block;
    color: #333333;
    margin-left: 203px;
    margin-bottom: 24px;
  }
}

.none {
  width: 100%;
  margin: 0 auto;
  margin-top: 124px;
  overflow: hidden;
  text-align: center;
  img {
    width: 218px;
    height: 218px;
    display: block;
    margin: 0 auto;
  }
  span {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    display: inline-block;
    margin-top: 24px;
    line-height: 23px;
  }
}
/deep/.ant-modal-footer {
  border-top: #15b7dd 0px solid;
}
/deep/.ant-modal-header {
  display: none;
}
/deep/.ant-col-4 {
  text-align: left;
  label {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
}
/deep/.ant-modal-body {
  padding: 24px 40px;
}
@media screen and (max-width: 16000px) {
  .main-wrap {
    .title-h4 {
      font-size: 22px !important;
    }
  }
  .Tip {
    font-size: 22px !important;
  }
}
</style>
