<template>
  <!-- 添加发票抬头/换开发票抬头 -->
  <div class="invoiceModal">
    <a-modal :width="602" :visible="isAddInvoiceModal" :closable="false">
      <div class="modal_box">
        <div class="box_title" v-if="invoiceType == 1">添加发票抬头</div>
        <div class="box_title" v-else>换开发票抬头</div>
        <div class="box_con">
          <div class="con_item require">
            <div class="item_title">抬头类型</div>
            <div class="item_con">
              <div
                class="con_type"
                :class="{ pick: index == headerType }"
                v-for="(item, index) in headerTypeArr"
                :key="index"
                @click="changeType(index)"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="con_item require">
            <div class="item_title">发票抬头</div>
            <div class="item_con">
              <a-input
                v-model="form.invoiceHeader"
                @input="searchInvoiceHeader(form.invoiceHeader)"
                placeholder="请填写发票抬头名称"
              />
              <div class="searchList">
                <p class="searchitem" @click="enterinvoice(item)" v-for="(item,index) in invoiceHeaderList" :key="index">{{item.nsrmc}}</p>
              </div>
              <!-- <a-select
                mode="multiple"
                label-in-value
                v-model="form.invoiceHeader"
                placeholder="请填写公司名称"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="fetchUser"
                @change="handleChange"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in data" :key="d.value">
                  {{ d.text }}
                </a-select-option>
              </a-select> -->
            </div>
          </div>
          <div class="con_item require" v-if="headerType == 1">
            <div class="item_title">税号</div>
            <div class="item_con">
              <a-input
                v-model="form.dutyParagraph"
                placeholder="请填写纳税人识别号"
              />
            </div>
          </div>
          <div class="con_item">
            <div class="item_title">电子邮箱</div>
            <div class="item_con">
              <a-input v-model="form.email" placeholder="请填写电子邮箱" />
            </div>
          </div>
          <template v-if="headerType == 1">
            <div class="con_item">
              <div class="item_title">开户银行</div>
              <div class="item_con">
                <a-input v-model="form.bank" placeholder="请填写开户银行" />
              </div>
            </div>
            <div class="con_item">
              <div class="item_title">银行账号</div>
              <div class="item_con">
                <a-input
                  v-model="form.bankAccount"
                  placeholder="请填写银行账号"
                />
              </div>
            </div>
            <div class="con_item">
              <div class="item_title">企业地址</div>
              <div class="item_con">
                <a-input
                  v-model="form.companyAddress"
                  placeholder="请填写企业地址"
                />
              </div>
            </div>
            <div class="con_item">
              <div class="item_title">企业电话</div>
              <div class="item_con">
                <a-input
                  v-model="form.companyPhone"
                  placeholder="请填写企业电话"
                />
              </div>
            </div>
          </template>
        </div>
        <div class="box_button">
          <div class="all-btn-blue" @click="onCancel()">取消</div>
          <div class="all-btn-small" @click="onConfirm()">
            保存<a-icon v-if="isLoading" type="loading" />
          </div>
        </div>
      </div>
      <template #footer></template>
    </a-modal>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: [
    "values", //判断是否为编辑
    "formemit", //父组件传值

    "isAddInvoiceModal", // 控制弹窗显示/隐藏  必填
    "invoiceType", //发票类型 1添加发票 2换开发票 3编辑发票抬头

    "invoiceRecordId", //发票id
  ],
  // 数据对象
  data() {
    return {
      isLoading: false, //提交
      headerType: 0, //抬头类型 1.企业 2.机关执业单位 3.个人/非企业单位 4.其他
      headerTypeArr: ["个人", "企业"],
      form: {
        headerType: -1, //抬头类型 1.企业 2.机关执业单位 3.个人/非企业单位 4.其他
        invoiceHeader: "", //抬头
        dutyParagraph: "", //税号
        email: "", //电子邮箱
        bank: "", //开户银行
        bankAccount: "", //银行账号
        companyAddress: "", //企业地址
        companyPhone: "", //企业电话
      },
      invoiceHeaderList: []
    };
  },
  // 事件处理器
  methods: {
    // 选择抬头
    enterinvoice(e) {
      this.$set(this.form,'invoiceHeader',e.nsrmc)
      if(this.headerType == 1){
        this.$set(this.form,'dutyParagraph',e.nsrsbh)
      }
      this.invoiceHeaderList = [];
    },

    // 发票抬头搜索
    searchInvoiceHeader(e){
      // console.log(e)
      if(e.length >= 6){
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$ajax({
            url: '/hxclass-pc/order/getCompanyInfo',
            method: 'get',
            params: {
              nsrmc: e
            }
          }).then(res=>{
            if(res.code == 200 && res.success){
              this.invoiceHeaderList = res.data;
            }
          })
        }, 200)        
      }else{
        this.invoiceHeaderList = [];
      }
    },

    // 初始化
    initForm() {
      if (this.invoiceType == 3) {
        this.form = this.formemit;
        this.headerType = this.formemit.headerType;
        // 处理值问题
        if (this.headerType == 3) {
          this.headerType = 0;
        }
      } else {
        this.headerType = 0;
        this.form = {
          headerType: -1, //抬头类型 1.企业 2.机关执业单位 3.个人/非企业单位 4.其他
          invoiceHeader: "", //抬头
          dutyParagraph: "", //税号
          email: "", //电子邮箱
          bank: "", //开户银行
          bankAccount: "", //银行账号
          companyAddress: "", //企业地址
          companyPhone: "", //企业电话
        };
      }
    },
    // 抬头类型
    changeType(id) {
      this.headerType = id;
    },
    // 取消
    onCancel(e) {
      this.$emit("onCancelAdd");
      this.form = {
        headerType: -1, //抬头类型 1.企业 2.机关执业单位 3.个人/非企业单位 4.其他
        invoiceHeader: "", //抬头
        dutyParagraph: "", //税号
        email: "", //电子邮箱
        bank: "", //开户银行
        bankAccount: "", //银行账号
        companyAddress: "", //企业地址
        companyPhone: "", //企业电话
      };
    },
    // 保存
    onConfirm() {
      // 抬头类型
      this.form.headerType = this.headerType == 0 ? 3 : 1;
      if (this.form.invoiceHeader == "") {
        this.$message.warning("请输入发票抬头");
        return;
      } else if (
        !this.$regular.invoiceTitle.reg.test(this.form.invoiceHeader)
      ) {
        this.$message.warning(this.$regular.invoiceTitle.msg);
        return;
      }
      // 企业类型
      if (this.headerType == 1) {
        if (this.form.dutyParagraph == "") {
          this.$message.warning("请输入税号");
          return;
        } else if (!this.$regular.TaxNumber.reg.test(this.form.dutyParagraph)) {
          this.$message.warning(this.$regular.TaxNumber.msg);
          return;
        }
      }
      // if (this.form.email == "") {
      //   this.$message.warning("请输入电子邮箱");
      //   return;
      // } else 
      if (this.form.email && !this.$regular.email.reg.test(this.form.email)) {
        this.$message.warning(this.$regular.email.msg);
        return;
      }

      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // 新增发票
      if (this.invoiceType == 1 || this.invoiceType == 3) {
        this.addInvoice();
      }
      // 换开发票
      if (this.invoiceType == 2) {
        this.changeInvoice();
      }
    },
    // 新增发票or编辑
    addInvoice() {
      if (this.form.dutyParagraph) {
        this.form.dutyParagraph = this.form.dutyParagraph.trim();
      }
      this.$ajax({
        url: "/hxclass-pc/invoice/header/",
        method: this.invoiceType == 1 ? "post" : "put",
        params: this.form,
      }).then((res) => {
        this.isLoading = false;
        if (res.code == 200 && res.success) {
          this.$message.success(this.invoiceType==1 ? '添加成功' : '修改成功');
          // 初始化
          this.initForm();
          this.$emit("onConfirmAdd");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 换开发票
    changeInvoice() {
      // this.form.invoiceRecordId = this.invoiceRecordId.split(",");
      this.form.invoiceRecordIds = Array.of(Number(this.invoiceRecordId));
      // this.form.invoiceRecordId = this.invoiceRecordId;
      if (this.form.dutyParagraph) {
        this.form.dutyParagraph = this.form.dutyParagraph.trim();
      }
       //trim()方法是用来删除字符串两端的空白字符并返回，trim方法并不影响原来的字符串本身，它返回的是一个新的字符串。缺陷：只能去除字符串两端的空格，不能去除中间的空格
      // console.log(this.form);
      let params = {
        bankNo: this.form.bankAccount, // 银行账号
        companyAddress: this.form.companyAddress,	// 公司地址
        companyPhone: this.form.companyPhone, // 公司电话
        email: this.form.email, //	邮箱
        invoiceRecordIds: this.form.invoiceRecordIds, // 发票记录id
        openBank: this.form.bank, // 开户行
        taxNo: this.form.dutyParagraph, // 税号
        title: this.form.invoiceHeader, // 抬头
        type: this.form.headerType,
        userInvoiceId: this.form.userInvoiceId,
      }
      this.$ajax({
        url: "/hxclass-pc/invoice/change",
        method: "post",
        params: params,
      }).then((res) => {
        this.isLoading = false;
        if (res.code == 200 && res.success) {
          this.$message.success(
            "发票正在开具中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。"
          );
          // 初始化
          this.initForm();
          this.$emit("onConfirmAdd");
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {
    this.initForm();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    formemit(newval) {
      this.initForm();
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
/deep/.ant-modal-footer {
  display: none;
}
.modal_box {
  .box_title {
    text-align: center;
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 23px;
    margin-top: 8px;
  }
  .box_con {
    margin-top: 30px;
    .con_item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      padding-left: 28px;
      .item_title {
        min-width: 64px;
        position: relative;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 19px;
      }
      &.require {
        .item_title {
          &::before {
            content: "*";
            position: absolute;
            left: -10px;
            display: inline-block;
            color: #f90000;
            font-size: 16px;
          }
        }
      }
      .item_con {
        display: flex;
        margin-left: 24px;
        position: relative;
        .con_type {
          transition: all 0.4s;
          width: 120px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          border-radius: 2px;
          border: 1px solid #c4c6cf;
          cursor: pointer;
          background-position: right bottom;
          &:nth-child(2) {
            margin-left: 32px;
          }
          &.pick {
            border: 1px solid #15b7dd;
            background-color: #f6fcfe;
            background-image: url("~@/assets/image/pay/right.png");
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: right bottom;
          }
        }
        .searchList{
          max-height: 200px;
          overflow: auto;
          position: absolute;
          top: 54px;
          z-index: 1;
          background-color: #ffffff;
          border: 1px solid #f5f5f5;
          .searchitem{
            border-bottom: 1px solid #f5f5f5;
            padding: 10px;
            line-height: 20px;
            font-size: 13px;
            color: #333333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .ant-input {
          width: 418px;
          height: 40px;
          padding: 4px 16px;
        }
      }
    }
  }
  .box_button {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding: 0 112px;
    .all-btn-blue,
    .all-btn-small {
      font-size: 16px;
      .anticon {
        margin-left: 10px;
      }
    }
  }
}
</style>
